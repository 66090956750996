/**
  Calendar skin config
 */
$datepicker__border-radius: 0.3rem;
$datepicker__day-margin: 0.3rem;
$datepicker__font-size: 1.4rem;
$datepicker__font-family: inherit;
$datepicker__item-size: 2.8rem;
$datepicker__margin: 0.8rem;
$datepicker__navigation-size: 0.9rem;
$datepicker__triangle-size: 8px;
$datepicker__selected-color: #FF6319;
$datepicker__border-color: #000;

@import "~react-datepicker/src/stylesheets/variables.scss";
@import "~react-datepicker/src/stylesheets/datepicker.scss";